import { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { Checklist } from '@components/ui/Checklist/Checklist';
import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import { ListItem, ListRendering } from '@sitecore/types/manual/List';
import { Box, ButtonLink, Grid, Heading, Stack, Stretch, Text, TextLink } from '@sparky';

// TODO: We should rename this component. Since this doens't really has anything to do with a list.
const List: FC<ListRendering> = ({ fields, params }) => {
  const { items, link, buttonLink, title } = fields;
  const Link = useLinkComponent();

  const headingLevel = params?.headingLevel ?? 'h3';

  return (
    <Box paddingX={{ initial: '6', md: '16', lg: '24' }} paddingY={{ initial: '10', md: '24' }}>
      <Stack alignY="center">
        <Grid.Item>
          {title?.value && (
            <Box paddingBottom="6">
              <Heading as={headingLevel} size="S" color="inherit">
                {wrap(title)}
              </Heading>
            </Box>
          )}
          {items.length && (
            <Checklist gap="5">
              {items.map((item: ListItem, key: number) => (
                <Checklist.Item key={key} icon="checkmark-filled" gap="3">
                  <Text size={{ initial: 'BodyM', md: 'BodyL' }} weight="bold">
                    {wrap(item.fields.title, <RichText html={item.fields.title.value} />)}
                  </Text>
                </Checklist.Item>
              ))}
            </Checklist>
          )}
          {buttonLink?.value.href && buttonLink?.value.text && (
            <Box paddingTop={{ initial: '6' }}>
              <Stretch width={{ initial: true, md: false }}>
                <Link
                  editable={buttonLink.editable}
                  linkType={fields.buttonLink.value.linktype}
                  linkValue={fields.buttonLink.value}>
                  <ButtonLink>{fields.buttonLink.value.text}</ButtonLink>
                </Link>
              </Stretch>
            </Box>
          )}
          {link?.value.href && link?.value.text && (
            <Box paddingTop={{ initial: '6' }}>
              <Stretch width={{ initial: true, md: false }}>
                <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
                  <TextLink emphasis="high">{link.value.text}</TextLink>
                </Link>
              </Stretch>
            </Box>
          )}
        </Grid.Item>
      </Stack>
    </Box>
  );
};

export default List;
